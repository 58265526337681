import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login.vue'),
        meta: {
            title: 'Login',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/account/register.vue'),
        meta: {
            title: 'Register',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/account/forgot-password.vue'),
        meta: {
            title: 'Forgot Password',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'default' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/',
        name: 'default',
        meta: {
            title: 'Dashboard',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/dashboard/index.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            title: 'Logout',
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear()
                sessionStorage.clear()
                next()
            },
        },
        component: () => import('../views/account/logout'),
    },
    {
        path: '/auth/signin-basic',
        name: 'signin-basic',
        meta: {
            title: 'Signin Basic',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/signin/basic'),
    },
    {
        path: '/auth/signin-cover',
        name: 'signin-cover',
        meta: {
            title: 'Signin Cover',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/signin/cover'),
    },
    {
        path: '/auth/signup-basic',
        name: 'signup-basic',
        meta: {
            title: 'Signup Basic',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/signup/basic'),
    },
    {
        path: '/auth/signup-cover',
        name: 'signup-cover',
        meta: {
            title: 'Signup Cover',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/signup/cover'),
    },
    {
        path: '/auth/reset-pwd-basic',
        name: 'reset-pwd-basic',
        meta: {
            title: 'Reset Password',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/reset/basic'),
    },
    {
        path: '/auth/reset-pwd-cover',
        name: 'reset-pwd-cover',
        meta: {
            title: 'Reset Password',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/reset/cover'),
    },
    {
        path: '/auth/create-pwd-basic',
        name: 'create-pwd-basic',
        meta: {
            title: 'create Password',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/create/basic'),
    },
    {
        path: '/auth/create-pwd-cover',
        name: 'create-pwd-cover',
        meta: {
            title: 'create Password',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/create/cover'),
    },
    {
        path: '/auth/lockscreen-basic',
        name: 'lock-screen-basic',
        meta: {
            title: 'Lock Screen',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/lockscreen/basic'),
    },
    {
        path: '/auth/lockscreen-cover',
        name: 'lock-screen-cover',
        meta: {
            title: 'Lock Screen',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/lockscreen/cover'),
    },
    {
        path: '/auth/twostep-basic',
        name: 'twostep-basic',
        meta: {
            title: 'Two Step Auth',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/twostep/basic'),
    },
    {
        path: '/auth/twostep-cover',
        name: 'twostep-cover',
        meta: {
            title: 'Two Step Auth',
            authRequired: true,
            appRoles: ['ROLE_ADMIN'],
        },
        component: () => import('../views/auth/twostep/cover'),
    },
    {
        path: '/quotes/list',
        name: 'Offertes',
        meta: { title: 'Offertes', authRequired: true },
        component: () => import('../views/quotes/list'),
    },
    {
        path: '/quotes/create',
        name: 'Offerte aanmaken',
        meta: { title: 'Offerte aanmaken', authRequired: true },
        component: () => import('../views/quotes/create'),
    },
    {
        path: '/quotes/detail/:entityId',
        name: 'Offerte bewerken',
        meta: { title: 'Offerte bewerken', authRequired: true },
        component: () => import('../views/quotes/detail'),
    },
    {
        path: '/orders/overview',
        name: 'Orderoverzicht',
        meta: { title: 'Orders', authRequired: true },
        component: () => import('../views/orders/overview'),
    },
    {
        path: '/orders/list',
        name: 'Orders',
        meta: { title: 'Orders', authRequired: true },
        component: () => import('../views/orders/list'),
    },
    {
        path: '/orders/create',
        name: 'Order aanmaken',
        meta: { title: 'Order aanmaken', authRequired: true },
        component: () => import('../views/orders/create'),
    },
    {
        path: '/orders/detail/:entityId',
        name: 'Order bewerken',
        meta: { title: 'Order bewerken', authRequired: true },
        component: () => import('../views/orders/detail'),
    },
    {
        path: '/companies/list',
        name: 'Bedrijven',
        meta: { title: 'Contactpersoon', authRequired: true },
        component: () => import('../views/companies/list'),
    },
    {
        path: '/companies/create',
        name: 'Bedrijf aanmaken',
        meta: { title: 'Bedrijf aanmaken', authRequired: true },
        component: () => import('../views/companies/create'),
    },
    {
        path: '/companies/detail/:companyId',
        name: 'Bedrijf bewerken',
        meta: { title: 'Bedrijf bewerken', authRequired: true },
        component: () => import('../views/companies/detail'),
    },
    {
        path: '/customers/list',
        name: 'Contactpersonen',
        meta: { title: 'Contactpersonen', authRequired: true },
        component: () => import('../views/customers/list'),
    },
    {
        path: '/customers/create',
        name: 'Contactpersoon aanmaken',
        meta: { title: 'Contactpersoon aanmaken', authRequired: true },
        component: () => import('../views/customers/create'),
    },
    {
        path: '/customers/detail/:customerId',
        name: 'Contactpersoon bewerken',
        meta: { title: 'Contactpersoon bewerken', authRequired: true },
        component: () => import('../views/customers/detail'),
    },
    {
        path: '/purchase-orders/list/:orderNumber?',
        name: 'Inkooporders',
        meta: { title: 'Inkooporders', authRequired: true },
        component: () => import('../views/purchase-orders/list'),
    },
    {
        path: '/purchase-orders/detail/:entityId',
        name: 'Inkooporder',
        meta: { title: 'Inkooporder', authRequired: true },
        component: () => import('../views/purchase-orders/detail'),
    },
    {
        path: '/playground/index',
        name: 'Playground',
        meta: { title: 'Playground', authRequired: true },
        component: () => import('../views/playground/index'),
    },
    {
        path: '/calendar/index',
        name: 'Calendar',
        meta: { title: 'Calendar', authRequired: true },
        component: () => import('../views/calendar/index'),
    },
    {
        path: '/not-allowed',
        name: 'Niet toegestaan',
        meta: { title: 'Niet toegestaan', appRoles: ['ROLE_ADMIN', 'ROLE_CONTACT', 'ROLE_USER'] },
        component: () => import('../views/not-allowed/index'),
    },
    {
        path: '/reports/kiyoh',
        name: 'Kiyoh overzicht',
        meta: { title: 'Kiyoh overzicht', authRequired: true },
        component: () => import('../views/reports/kiyoh'),
    },
    {
        path: '/reports/quote-reminder',
        name: 'Offerte herinnering',
        meta: { title: 'Offerte herinnering', authRequired: true },
        component: () => import('../views/reports/quote-reminder'),
    },
    {
        path: '/reports/margin',
        name: 'Marge controle',
        meta: { title: 'Marge controle', authRequired: true },
        component: () => import('../views/reports/margin'),
    },
    {
        path: '/notifications/list',
        name: 'Notificaties',
        meta: { title: 'Notificaties', authRequired: true },
        component: () => import('../views/notifications/list'),
    },
    {
        path: '/tasks/list',
        name: 'Taken',
        meta: { title: 'Taken', authRequired: true },
        component: () => import('../views/tasks/list'),
    },
    {
        path: '/after-sales/list',
        name: 'After sales',
        meta: { title: 'After sales', authRequired: true },
        component: () => import('../views/after-sales/list'),
    },
    {
        path: '/abandoned-carts/list',
        name: 'Abandonded carts',
        meta: { title: 'Verlaten offertes', authRequired: true },
        component: () => import('../views/abandoned-carts/list'),
    },
    {
        path: '/payments/list',
        name: 'Betalingen',
        meta: { title: 'Betalingen', authRequired: true },
        component: () => import('../views/payments/list'),
    },
]
